* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
}

p {
	font-size: 1.8rem;
}
a {
	text-decoration: none;
	font-size: 1.3rem;
	color: #1ed760;
	transition: color 0.3s;
}
a:hover {
	color: #1bb752;
}

button,
input,
select {
	font-size: 1.5rem;
}

body {
	min-height: 100vh;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	background: radial-gradient(#f2f2f2 40%, #c9c9c9 100%);
}

.login-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.btn-login,
.btn-search {
	padding: 10px 20px;
	font-weight: bold;
	color: white;
	background-color: #1ed760;
	border: none;
	cursor: pointer;
}

.btn-search {
	border-radius: 0 20px 20px 0;
}

.btn-login {
	border-radius: 10px;
	margin-bottom: 10px;
}
.btn-login:hover,
.btn-search:hover {
	background-color: #1bb752;
}

.box-app {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 95vh;
	margin-bottom: 10px;
}

.logo {
	width: 200px;
	margin: 10px;
	filter: drop-shadow(0 0 10px rgb(254, 254, 254));
}

.search {
	width: 70%;
	padding: 10px 20px;
	border: none;
	border-radius: 20px 0 0 20px;
}

.error-info {
	margin: 5px 0;
	color: tomato;
}

.box-filter {
	margin: 10px;
	text-align: center;
}

select {
	margin: 5px;
	padding: 5px 10px;
	border-radius: 20px;
	border: none;
}

.option-default {
	text-align: center;
}

.box-track {
	flex-grow: 1;
	width: 95%;
	padding: 20px 15px;
	text-align: center;
	border-radius: 30px;
	background-color: rgb(255, 255, 255);
}
.item-track {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 10px 0;
	padding: 15px 20px;
	background: radial-gradient(#f2f2f2 40%, #e3e2e2 100%);
	border-radius: 40px;
}

.item-track img {
	border-radius: 20px;
}

.head-item-track {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.head-item-track img {
	margin-right: 10px;
}

.item-track div {
	text-align: left;
}

audio {
	filter: drop-shadow(0 0 10px rgb(217, 217, 217));
	width: 100%;
}

@media (min-width: 576px) {
	.search {
		width: 55%;
	}
	.item-track {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	audio {
		width: 100%;
	}
	.head-item-track {
		margin-bottom: 0;
	}
}
@media (min-width: 768px) {
	audio {
		width: 60%;
	}
}
@media (min-width: 922px) {
}
